import React from 'react';
import Layout from '../components/Layout';
import BackgroundImage from 'gatsby-background-image';
import ColourBanner from '../../src/components/ColourBanner';
import { graphql } from 'gatsby';

export default ({ data, location }) => {
  return (
    <Layout location={location} title={data.site.siteMetadata.title} page={'coloc'}>
      <BackgroundImage
        style={{height: '75vh', paddingTop: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center'}} 
        fluid={data.dReflection.childImageSharp.fluid}
        alt="Colourful image with a reflection of a man wearing a hoodie and sunnies infront of Canadian nature.">
        <ColourBanner colour={'hsl(315, 13%, 71%, 0.8)'}>
          <section name="about">
            <h2 className="title">about.</h2>
            <div>
              <div className="prose">
                <p>a coloc is the person who tells you how to get the bus from the airport.</p>
                <p>a coloc is the friend who invites you to their thanks giving dinner, when you’re completely new to town.</p>
                <p>a coloc is the flat mate who folds your clothes that you left on the airer.</p>
                <p>a coloc is the partner who offers you dinner when you get in late.</p>
                <p>we want to build a community of colocs all around the world and make the world a little bit cozier, and with your help we think we can.</p>
              </div>
            </div>
          </section>
        </ColourBanner>
      </BackgroundImage>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    dReflection: file(relativePath: { eq: "photos/d-reflection.jpg" }) {
      ...maxWidthImage
    }
  }
`;